import React from 'react'

const ChaliceImage = () => {
    return (<>
    <img className='text-center image-responsive' style={{marginTop: 15} }width='150' src='https://www.uua.org/files/styles/max_1200x1200/public/png/s/symbol_gradient.png?itok=UaABvFXr' alt='chalice'></img>

    </>
    )
}

export default ChaliceImage